import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import {
  addRolesToUser,
  fetchUsers,
} from "../../../../store/usersApi/usersThunks";

const AddRole_component = ({ userId, userRoles, onRolesUpdated }) => {
  const [selectedRoles, setSelectedRoles] = useState([]);
  const validRoles = ["Admin", "Supervisor", "Consultant", "Copywriter"];

  const dispatch = useDispatch();
  useEffect(() => {
    setSelectedRoles(userRoles);
  }, [userRoles]);

  const handleAddRoles = async (e) => {
    e.preventDefault();

    // if (selectedRoles.length === 0) {
    //   toast.error("Please select at least one role.");
    //   return;
    // }
    // console.log("selectedRoles: ", selectedRoles);
    try {
      const resultAction = await dispatch(
        addRolesToUser({ userId, roles: selectedRoles })
      );
      if (addRolesToUser.fulfilled.match(resultAction)) {
        toast.success("Roles updated successfully!");
        onRolesUpdated();
        // Fetch users again to update the list
        dispatch(fetchUsers());
      } else {
        toast.error(resultAction.payload || "Failed to add roles to user.");
      }
    } catch (error) {
      toast.error(`Failed to add roles: ${error.message}`);
    }
  };

  const handleRoleChange = (role) => {
    setSelectedRoles((prev) =>
      prev.includes(role) ? prev.filter((r) => r !== role) : [...prev, role]
    );
  };

  return (
    <form onSubmit={handleAddRoles} className="mb-4">
      <div className="flex space-x-3">
        {validRoles.map((role) => (
          <label key={role} className="flex items-center">
            <input
              type="checkbox"
              value={role}
              checked={selectedRoles.includes(role)}
              onChange={() => handleRoleChange(role)}
              className="mr-1"
            />
            {role}
          </label>
        ))}
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Add Roles
        </button>
      </div>
    </form>
  );
};

export default AddRole_component;
