import React from "react";

const BannerCard = ({ banner, handleDelete, handleMoveBannerIndex, index }) => {
  return (
    <div className="banner-card p-4 border rounded-lg shadow-md">
      <img
        src={banner}
        alt="Banner"
        className="w-full h-48 object-cover rounded-md mb-4"
      />
      <div className="flex justify-between">
        <button
          onClick={() => handleDelete(banner)}
          className="bg-red-600 hover:bg-red-500 text-white font-medium rounded-lg text-sm px-4 py-2 focus:ring-4 focus:outline-none focus:ring-red-300"
        >
          Delete
        </button>
        <div>
          <button
            onClick={() => handleMoveBannerIndex(index, index - 1)}
            className="bg-blue-600 hover:bg-blue-500 text-white font-medium rounded-lg text-sm px-4 py-2 mr-2 focus:ring-4 focus:outline-none focus:ring-blue-300"
          >
            Move Up
          </button>
          <button
            onClick={() => handleMoveBannerIndex(index, index + 1)}
            className="bg-blue-600 hover:bg-blue-500 text-white font-medium rounded-lg text-sm px-4 py-2 focus:ring-4 focus:outline-none focus:ring-blue-300"
          >
            Move Down
          </button>
        </div>
      </div>
    </div>
  );
};

export default BannerCard;
