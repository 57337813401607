import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/api"; // Adjust the path according to your structure

export const fetchBanners = createAsyncThunk(
  "banner/fetchBanners",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/banner");
      // console.log("response:", response);
      return response.data.data.bannerImages;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const createBanner = createAsyncThunk(
  "banner/createBanner",
  async (bannerData, { rejectWithValue }) => {
    try {
      const response = await axios.post("/banner", bannerData);
      // console.log("response:", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const deleteBanner = createAsyncThunk(
  "banner/deleteBanner",
  async (imageUrl, { rejectWithValue }) => {
    // console.log("imageUrl:", imageUrl);
    try {
      const response = await axios.delete("/banner", {
        data: { imageUrl },
      });
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const moveBannerIndex = createAsyncThunk(
  "banner/moveBannerIndex",
  async ({ oldIndex, newIndex }, { rejectWithValue }) => {
    try {
      const response = await axios.post("/banner/moveimage", { oldIndex, newIndex });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);