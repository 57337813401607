import React from "react";
import AddRole_component from "./AddRole_component";
import { useSelector } from "react-redux";

const TableRow = ({
  user,
  onToggleBlockUser,
  onChangeRole,
  onRolesUpdated,
}) => {
  const { data: currentUser } = useSelector((state) => state.users.currentUser);
  console.log("currentUser: ", currentUser);
  return (
    <tr>
      <td className="px-4 py-2">{user.fullName}</td>
      <td className="px-4 py-2">{user.gender}</td>
      <td className="px-4 py-2">{user.phone}</td>
      {/* <td className="px-4 py-2">{user.roles.join(", ")}</td> */}
      <td className="px-4 py-2 text-right">
        <button
          onClick={() => onToggleBlockUser(user._id, user.isBlocked)}
          className={`px-4 py-2 rounded ${
            user.isBlocked ? "bg-red-500" : "bg-green-500"
          } text-white`}
        >
          {user.isBlocked ? "Unblock" : "Block"}
        </button>
        {currentUser.admin && (
          <AddRole_component
            userId={user._id}
            userRoles={user.roles}
            onRolesUpdated={onRolesUpdated}
          />
        )}
      </td>
    </tr>
  );
};

export default TableRow;
