// Routers.js
import { Route, Routes } from 'react-router-dom';
import Admin from './admin/Admin';
import RouteScroll from './utils/RouteScroll';
import Authentication from './protected/Protected';
import Dashboard from './admin/adminComponents/Dashboard';
import { AdminOffice } from './admin/adminComponents/office/Office';
import AdminUsers from './admin/adminComponents/users/Users';
import { AdminTournaments } from './admin/adminComponents/tournaments/Tournaments';
import { AdminTournamentsSingle } from './admin/adminComponents/tournaments/TournamentsSingle';
import { useEffect, useState } from 'react';
import LazyLoading from './components/LazyLoad';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCurrentUser } from './store/usersApi/usersThunks';
import SignIn from './admin/adminComponents/auth/Login';
import AdminJobs from './admin/adminComponents/jobs/QuickJobs';
import AdminStandartJobs from './admin/adminComponents/jobs/StandartJobs';
import AdminDiscovers from './admin/adminComponents/discovers/Discovers';
import Companies from './admin/adminComponents/companies/Companies';
import Requests_section from './admin/adminComponents/requestsSection/Requests_section';
import Admin_managers from './admin/adminComponents/admin_managers_sections/Admin_managers';
import Support_section from './admin/adminComponents/support_section/Support_section';
import DiscoverSingle from './admin/adminComponents/discovers/DiscoverSingle';
import EditCompany from './admin/adminComponents/companies/EditCompany';
import Banner from './admin/adminComponents/banner/Banner';

function Routers() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  if (loading) {
    return <LazyLoading />;
  }

  return (
    <div>
      <RouteScroll />
      <Routes>
        <Route path="/login" element={<SignIn />} />
        <Route element={<Authentication />}>
          <Route path="/*" element={<Admin />} />
          <Route path="/admin" element={<Admin />}>
            <Route path="" element={<Dashboard />} />
            <Route path="banner" element={<Banner />} />
            <Route path="companies" element={<Companies />} />
            <Route path="companies/edit/:id" element={<EditCompany />} />
            <Route path="admin_managers" element={<Admin_managers />} />
            <Route path="requests" element={<Requests_section />} />
            <Route path="support_section" element={<Support_section />} />
            <Route path="office" element={<AdminOffice />} />
            <Route path="users" element={<AdminUsers />} />
            <Route path="tournaments" element={<AdminTournaments />} />
            <Route path="jobs" element={<AdminJobs />} />
            <Route path="jobs/standart" element={<AdminStandartJobs />} />
            <Route path="discovers" element={<AdminDiscovers />} />
            <Route path="discovers/:id" element={<DiscoverSingle />} />
            <Route
              path="tournaments/:id"
              element={<AdminTournamentsSingle />}
            />
          </Route>
        </Route>
      </Routes>
    </div>
  );
}

export default Routers;
