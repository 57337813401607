import React, { useState } from "react";

const AddBannerForm = ({ setShowModal, onSubmit }) => {
  const [formData, setFormData] = useState(null);

  const handleFileChange = (e) => {
    setFormData(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("banner", formData);

    console.log("data:", data);
    onSubmit(data);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
        <span
          className="text-gray-500 hover:text-gray-700 cursor-pointer float-right"
          onClick={() => setShowModal(false)}
        >
          &times;
        </span>
        <h2 className="text-2xl mb-4">Add New Banner</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="file"
            name="bannerImage"
            onChange={handleFileChange}
            accept="image/png image/jpeg image/jpg"
            className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none mb-4"
          />
          <button
            type="submit"
            className="w-full bg-blue-600 hover:bg-blue-500 text-white font-medium rounded-lg text-sm px-5 py-2.5 focus:ring-4 focus:outline-none focus:ring-blue-300"
          >
            Upload
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddBannerForm;
