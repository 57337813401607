import { createSlice } from "@reduxjs/toolkit";
import {
  fetchBanners,
  createBanner,
  deleteBanner,
  moveBannerIndex,
} from "./bannerThunks";

const initialState = {
  banners: [],
  status: "idle",
  error: null,
};

const bannerSlice = createSlice({
  name: "banner",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBanners.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBanners.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.banners = action.payload;
      })
      .addCase(fetchBanners.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(createBanner.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createBanner.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.banners.push(action.payload.data.bannerImages[action.payload.data.bannerImages.length - 1]);
      })
      .addCase(createBanner.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(deleteBanner.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteBanner.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.banners = state.banners.filter(
          (banner) => banner !== action.payload.imageUrl
        );
      })
      .addCase(deleteBanner.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(moveBannerIndex.pending, (state) => {
        state.status = "loading";
      })
      .addCase(moveBannerIndex.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.banners = action.payload.data.bannerImages;
      })
      .addCase(moveBannerIndex.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default bannerSlice.reducer;