import { createSlice } from '@reduxjs/toolkit';
import {
  fetchUsers,
  fetchUserById,
  fetchCurrentUser,
  updateJobSeekerProfile,
  updateEmployerProfile,
  updateServiceProfile,
  favoriteUser,
  fetchFavoriteUsers,
  updateRoleUser,
  removeFavoriteUser,
  registerUserByAdmin,
  addRolesToUser,
} from './usersThunks'; // Adjust the path according to your structure

const initialState = {
  users: [],
  changeRole: false,
  favoriteUser: [],
  currentPage: 1,
  totalPages: 1,
  status: 'idle',
  error: null,
  errorCurrent: null,
  singleUser: null,
  currentUser: null,
};

const usersSlice = createSlice({
  name: 'usersApi',
  initialState,
  reducers: {
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.users = action.payload.users;
        state.totalPages = action.payload.totalPages;
        state.currentPage = action.payload.currentPage;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchUserById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.singleUser = action.payload; // Adjust based on actual response structure
      })
      .addCase(fetchUserById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchCurrentUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCurrentUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.currentUser = action.payload; // Adjust based on actual response structure
      })
      .addCase(fetchCurrentUser.rejected, (state, action) => {
        state.status = 'failed';
        state.errorCurrent = action.error.message;
        // console.log('fetchCurrentUser rejected: ', action.error.message);
      })
      .addCase(updateJobSeekerProfile.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateJobSeekerProfile.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.currentUser = action.payload; // Update currentUser or handle as needed
      })
      .addCase(updateJobSeekerProfile.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(updateEmployerProfile.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateEmployerProfile.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.currentUser = action.payload; // Update currentUser or handle as needed
      })
      .addCase(updateEmployerProfile.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(updateServiceProfile.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateServiceProfile.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.currentUser = action.payload; // Update currentUser or handle as needed
      })
      .addCase(updateServiceProfile.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(favoriteUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(favoriteUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.users = action.payload; // Update currentUser or handle as needed
      })
      .addCase(favoriteUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchFavoriteUsers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchFavoriteUsers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.favoriteUser = action.payload.favoriteUser;
        state.totalPages = action.payload.totalPages;
        state.currentPage = action.payload.currentPage;
      })
      .addCase(fetchFavoriteUsers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(updateRoleUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateRoleUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.changeRole = !state.changeRole; // Update currentUser or handle as needed
      })
      .addCase(updateRoleUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      .addCase(removeFavoriteUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(removeFavoriteUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(removeFavoriteUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.quickJobs = action.payload; // Update currentUser or handle as needed
      })
      .addCase(registerUserByAdmin.pending, (state) => {
        state.status = 'loading';
      })
      // Handle registerUserByAdmin fulfilled
      .addCase(registerUserByAdmin.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // The response might just be a success message. 
        // If you need to do something with the returned user data, 
        // you can handle that here. For example:
        // state.users = [...state.users, action.payload.newlyCreatedUser]; 
        // (Adjust based on the actual response structure)
      })
      // Handle registerUserByAdmin rejected
      .addCase(registerUserByAdmin.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(addRolesToUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addRolesToUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Update the user roles in the state if needed
        if (state.singleUser && state.singleUser._id === action.payload._id) {
          state.singleUser.roles = action.payload.roles;
        }
      })
      .addCase(addRolesToUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { setCurrentPage } = usersSlice.actions;
export default usersSlice.reducer;
