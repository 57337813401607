import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchBanners,
  createBanner,
  deleteBanner,
  moveBannerIndex,
} from "../../../store/bannerApi/bannerThunks";
import LazyLoading from "../../../components/LazyLoad";
import toast from "react-hot-toast";
import BannerCard from "./BannerCard"; // Create this component similar to DiscoverCard
import AddBannerForm from "./AddBannerForm"; // Create this component similar to DiscoverCard
const Banner = () => {
  const dispatch = useDispatch();
  const { banners, status, error } = useSelector((state) => state.banner);
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    dispatch(fetchBanners());
  }, [dispatch]);

  const handleNewBanner = (formData) => {
    dispatch(createBanner(formData)).then(() => {
      setShowModal(false);
      toast.success("Banner created successfully!");
    });
  };

  const handleDelete = (imageUrl) => {
    dispatch(deleteBanner(imageUrl)).then(() => {
      toast.success("Banner deleted successfully!");
    });
  };

  const handleMoveBannerIndex = (oldIndex, newIndex) => {
    dispatch(moveBannerIndex({ oldIndex, newIndex })).then(() => {
      toast.success("Banner index moved successfully!");
    });
  };

  return (
    <div className="p-3 sm:p-5">
      <div className="lg:ml-64">
        <div className="rounded-lg dark:border-gray-700 lg:mt-16 mt-0">
          <h3 className="text-gray-900 dark:text-gray-800 mb-3 text-2xl font-bold">
            Banners
          </h3>
          <button
            onClick={() => setShowModal(true)}
            className="bg-blue-600 hover:bg-blue-500 text-white font-medium rounded-lg text-sm px-5 py-2.5 focus:ring-4 focus:outline-none focus:ring-blue-300"
          >
            Add Banner
          </button>
        </div>

        {/* Loading & Error Messages */}
        {error && <p className="text-center text-red-500">Error: {error}</p>}
        {status === "loading" ? (
          <LazyLoading />
        ) : (
          <div className="grid mt-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6">
            {banners?.length > 0 ? (
              banners.map((banner, index) => (
                <BannerCard
                  key={index}
                  banner={banner}
                  handleDelete={handleDelete}
                  handleMoveBannerIndex={handleMoveBannerIndex}
                  index={index}
                />
              ))
            ) : (
              <p className="text-center text-gray-600">No banners found.</p>
            )}
          </div>
        )}
      </div>

      {/* Modal for Adding Banner */}
      {showModal && (
        <AddBannerForm setShowModal={setShowModal} onSubmit={handleNewBanner} />
      )}
    </div>
  );
};

export default Banner;
